<template>
  <Button v-bind="props" data-cy="npm-install" @click="showModal">
    <slot />
  </Button>
  <InstallModal />
</template>

<script lang="ts" setup>
import Button from '@cypress-design/vue-button'
import type { ButtonVariants, ButtonSizes } from '@cypress-design/constants-button'
import InstallModal from '@/components/Vue/InstallModal.vue'
import { showInstallModalStore } from '@/stores'

const props = defineProps<{
  variant?: ButtonVariants
  size?: ButtonSizes
  class?: string | string[]
}>()

function showModal() {
  showInstallModalStore.value = true
}
</script>
