<template>
  <Modal v-if="mounted" v-model:show="isOpen" title="Installing Cypress" help-link="https://on.cypress.io/install">
    <div class="p-[16px] pt-[8px]">
      <InstallContent data-prefix="modal-" />
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import Modal from '@cypress-design/vue-modal'
import { showInstallModalStore } from '@/stores'
import InstallContent from './InstallContent.vue'
import useMounted from '@/utilities/useMounted'

// <windi-keep class="slide-out-leave-active slide-out-enter-from slide-out-leave-to" />
const isOpen = ref<boolean>(false)

const mounted = useMounted()

watch(
  showInstallModalStore,
  (val) => {
    if (isOpen.value !== val) {
      isOpen.value = val
    }
  },
  { immediate: true },
)

watch(isOpen, (val) => {
  if (val !== undefined && showInstallModalStore.value !== val) {
    showInstallModalStore.value = val
  }
})
</script>
